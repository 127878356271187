<template>
  <v-container fluid>
    <TableViewComponent
      title="Table super activités"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter une super activité"
      itemLabel="super activité"
      itemsLabel="super activités"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette super activité ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { SuperActivitiesService } from "@/service/sfr/super_activities_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableSuperActivities",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /**le classe d'accès au service */
      service: null,
      serviceActivities: null,
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      this.$router.push(routes.superActivities.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];

        // Récupère la liste des super établissements
        let entities = await this.service.getAll();
        // Récupère la liste des établissements
        let activities = await this.serviceActivities.getActivities();


        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));

          // Récupère l'objet activité pour l'activité principale
          if (e.mainActivity) {
            let main = activities.find((act) => act.id == entity.mainActivity);
            entity.main = main;
          }

          // Récupère le nombre d'activités associés
          entity.nbActivities = "aucunes activités";
          let nb = entity.activities.length;
          if (nb > 0) {
            if (nb > 1) {
              entity.nbActivities = nb + " activités";
            } else if (nb == 1) {
              entity.nbActivities = "1 activité";
            }
          }

          entity.view = () => {
            this.$router.push(
              routes.superActivities.view.root + "/" + entity.id
            );
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.superActivities.edit.root + "/" + entity.id
            );
          };

          entity.delete = async () => {
            try {
              await this.service.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de la super activité : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
        },
        {
          text: "Nom complet",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Activité principale",
          align: "start",
          sortable: true,
          value: "main.digitalName",
        },
        {
          text: "Nombre d'activité",
          align: "start",
          sortable: true,
          value: "nbActivities",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditServiceSFR];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditServiceSFR, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadServiceSFR];
    },
  },
  mounted() {
    //on instancie les services
    this.service = new SuperActivitiesService(
      this.$api.getSuperActivitiesApi()
    );
    this.serviceActivities = new ActivityService(this.$api);

    this.load();
  },
};
</script>

<style>
</style>