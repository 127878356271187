var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("TableViewComponent", {
        attrs: {
          title: "Table super activités",
          addItemLabel: "ajouter une super activité",
          itemLabel: "super activité",
          itemsLabel: "super activités",
          items: _vm.entities,
          columns: _vm.buildColumns(),
          deleteItemLabel: "Voulez-vous supprimer cette super activité ?",
          loading: _vm.loading,
          rolesForEdition: _vm.rolesForEdition,
          rolesForAddDelete: _vm.rolesForAddDelete,
          rolesForRead: _vm.rolesForRead,
        },
        on: {
          addItemEvent: function ($event) {
            return _vm.onAddElement()
          },
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }